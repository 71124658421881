#top-bar {
  background: #fff;
  color: #fff;
  -webkit-transition: all 0.2s ease-out 0s;
  transition: all 0.2s ease-out 0s;
  padding: 15px 0;
  -webkit-box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid #dedede;
}

.required {
  & label:after {
    content: '*';
    color: $danger;
    padding-left: 5px;
  }
}

ul.step-anchor {
  display: unset;
  color: #bbb;
  text-decoration: none;
  position: relative;
  border: 0 !important;
  border-radius: 0;
  outline-style: none;
  background: #f5f5f5;
  width: 100%;

  .style-a-hidden {
    color: gray;
    text-decoration: none;
    position: relative;
    display: block;
    border: 0 !important;
    border-radius: 0;
    outline-style: none;
    background: $gray-300;
    padding: 10px 20px;
    font-size: 0.75rem;


    &.active {
      background: $info;
      color: white;

      &::after {

        border-left: 15px solid $info;
      }
    }

    &.done {
      background: $success;
      color: white;

      &::after {

        border-left: 15px solid $success;
      }
    }

    &::before {
      content: " ";
      display: block;
      width: 0;
      height: 0;
      border-top: 28px solid transparent;
      border-bottom: 28px solid transparent;
      border-left: 15px solid $gray-400;
      position: absolute;
      top: 50%;
      margin-top: -28px;
      margin-left: 1px;
      left: 100%;
      z-index: 1;
    }

    &::after {
      content: " ";
      display: block;
      width: 0;
      height: 0;
      border-top: 28px solid transparent;
      border-bottom: 28px solid transparent;
      border-left: 15px solid $gray-300;
      position: absolute;
      top: 50%;
      margin-top: -28px;
      left: 100%;
      z-index: 1;
    }
  }

  li:last-child .style-a-hidden::after,
  li:last-child .style-a-hidden::before {
    border: 0;
  }

}

.form-switch {
  padding-top: 2rem !important;

  input {
    margin-right: 0.7rem;
  }

  label {
    margin-top: 0.3rem;
  }

  .form-check-input {
    width: 2.7rem;
    height: 1.4rem;
  }
}

.hr-primary {
  background-image: -webkit-linear-gradient(left, rgba(0, 121, 163, .8), rgba(0, 121, 163, .6), rgba(0, 0, 0, 0));
  border-top: none;
}

hr {
  height: 4px !important;
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 20px;
}

.message-error {
  display: block;
  width: 100%;
  margin-top: 1rem;
  font-size: 0.875em;
  color: #dc3545;
}
